/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

html {
  font-size: 14px;
  position: relative;
  min-height: 100%;
}

@media (min-width: 768px) {
html {
  font-size: 16px;
}
}

body {
margin-bottom: 60px;
}

canvas {
  background-color: black;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


li.side_button {
  padding: 10px 0;
  overflow: hidden;
}

div.left_sidebar {
  /* height: 1000px; */
  background-color: rgb(226, 226, 226);
  max-width: 200px;
  float: left;
}

div.time {
  margin: 0 0;
  padding: 1px 3px;
  line-height: 1.2;
  background-color: rgb(255, 255, 255);
  float: right;
  text-align: right;
  font-weight: 600;
}



a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

a {
  color: #0077cc;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px;
}


